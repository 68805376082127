// tslint:disable-next-line: no-var-requires
require('vue-image-lightbox/dist/vue-image-lightbox.min.css');
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import DunatiLoaderComponent from '@/components/dunati-loader/dunati-loader.component';
import { SectionGalery } from '@/class/sectionGalery';
import JQuery from 'jquery';
const $ = JQuery;

const auxComponent: any = {
  DunatiLoaderComponent
};

if (typeof window !== 'undefined') {
  // @ts-ignore
  const LightBox = () => import('vue-image-lightbox');
  // @ts-ignore
  const carousel = () => import('vue-owl-carousel');

  auxComponent.carousel = carousel;
  auxComponent.LightBox = LightBox;
}

@Component({
  components: auxComponent,
})
export default class SectionGaleryComponent extends Vue {
  @Prop({ default: null })
  // @ts-ignore
  public section: SectionGalery;

  // carousel
  public scrollable = true;
  public autoplay = true;

  public carouselResponsive = {
    320: {
      items: 1,
      autoHeight: true,
      mouseDrag: false,
      touchDrag: true,
      autoplay: this.autoplay,
      autoplayHoverPause: true,
    },
    767: {
      items: 2,
      autoHeight: true,
      mouseDrag: false,
      touchDrag: true,
      autoplay: this.autoplay,
      autoplayHoverPause: true,
    },
    992: {
      items: 3,
      nav: true,
      autoHeight: true,
      mouseDrag: false,
      touchDrag: true,
      autoplay: false,
    },
    1140: {
      items: 4,
      nav: true,
      autoplay: false,
    },
  };

  public mounted(): void {
    window.addEventListener('touchmove', this.preventBehavior, { passive: false });
  }

  public destroyed(): void  {
    window.removeEventListener('touchmove', this.preventBehavior);
  }

  public updated(): void  {
    this.$nextTick(() => {
      $('#carouselImg .owl-carousel .owl-item img').click((el) => {
        this.showImgSlider((el.target.dataset.index) ? +el.target.dataset.index : 0);
      });

      const owl = $('#carouselImg .owl-carousel');

      owl.on('drag.owl.carousel', (event) => {
        document.ontouchmove = (e) => {
          e.preventDefault();
        };
      });

      owl.on('dragged.owl.carousel', (event) => {
        document.ontouchmove = (e) => {
          return true;
        };
      });

    });
  }

  public showImgSlider(index: number): void {
    if (typeof (index) === 'number') {
      // @ts-ignore
      if (this.$mq === 'sm' || this.$mq === 'xs') {
        return;
      }

      // @ts-ignore
      this.$refs.lightBox.showImage(index);
    }
  }

  public getListImages(): object[] {
    // @ts-ignore
    let files = (this.$mq === 'sm' || this.$mq === 'xs')? this.section.fileSmallUrls : this.section.fileUrls;

    if (!files || !files.length) return [];

    let list: object[] = [];
    files.forEach((element) => {
      if (element !== '') {
        list.push({
          src: element
        });
      }
    })
    
    return list;
  }

  public dragged(): void {
    $('body').css('overflow', 'auto');
    this.scrollable = true;
  }

  public drag() {
    if (window.innerWidth <= 768) {
      $('body').css('overflow', 'hidden');
    }
    this.scrollable = false;
  }

  public preventBehavior(e: any): void {
    if (!this.scrollable) {
      e.preventDefault();
    }
  }

  public onLoaded(id: string): void {
    const cls: string = `.${id}`;
    $(cls).hide();
  }
}

