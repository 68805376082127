import { StrapiFile } from './strapiFile';

export class ComparativeItem {
  public id!: string;
  public title!: string;
  public description!: string;
  public img!: StrapiFile;

  public updatedAt!: string;
  public createdAt!: string;


  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.title = data.title;
      this.description = data.description;

      if (data.img) {
        this.img = new StrapiFile(data.img);
      }
      
      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
    }
  }
}


export class SectionComparative {
  public id!: string;
  public title!: string;
  public subtitle!: string;
  public background!: string;
  public position!: number;
  public sectionType!: string;

  public items: ComparativeItem[] = [];

  public updatedAt!: string;
  public createdAt!: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.title = data.title;
      this.subtitle = data.subtitle;
      this.background = data.background;
      this.position = +data.position;
      this.sectionType = data.section_type;

      if (data.items) {
        data.items.forEach((x: any) => {
          this.items.push(new ComparativeItem(x))
        });
      }


      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
    }
  }
}
