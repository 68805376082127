import { StrapiFile } from "./strapiFile";

export class SectionTextText {
  public id!: string;
  public position!: number;
  public sectionType!: string;

  public leftBackground!: string;
  public leftButtonTargetBlank!: boolean;
  public leftButtonText!: string;
  public leftEnableButton!: boolean;
  public leftButtonUrl!: string;
  public leftJustifyContent!: string;
  public leftText!: string;
  public leftTextAlign!: string;
  public leftTextDark!: boolean;
  public rightBackground!: string;
  public rightButtonTargetBlank!: boolean;
  public rightButtonText!: string;
  public rightEnableButton!: boolean;
  public rightButtonUrl!: string;
  public rightJustifyContent!: string;
  public rightText!: string;
  public rightTextAlign!: string;
  public rightTextDark!: boolean;

  public updatedAt!: string;
  public createdAt!: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.position = +data.position;

      this.sectionType = data.section_type;
      this.leftBackground = data.left_background;
      this.leftButtonTargetBlank = data.left_button_target_blank? true : false;
      this.leftButtonText = data.left_button_text;
      this.leftEnableButton = data.left_enable_button? true : false;
      this.leftButtonUrl = data.left_button_url;
      this.leftJustifyContent = data.left_justify_content;
      this.leftText = data.left_text;
      this.leftTextAlign = data.left_text_align;
      this.leftTextDark = data.left_text_dark? true : false;

      this.rightBackground = data.right_background;
      this.rightButtonTargetBlank = data.right_button_target_blank? true : false;
      this.rightButtonText = data.right_button_text;
      this.rightEnableButton = data.right_enable_button? true : false;
      this.rightButtonUrl = data.right_button_url;
      this.rightJustifyContent = data.right_justify_content;
      this.rightText = data.right_text;
      this.rightTextAlign = data.right_text_align;
      this.rightTextDark = data.right_text_dark? true : false;

      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
    }
  }
}
