import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SectionComparative } from '@/class/sectionComparative';
const auxComponent: any = {};

@Component({
  components: auxComponent,
})
export default class SectionComparativeComponent extends Vue {
  @Prop({ default: null })
  // @ts-ignore
  public section: SectionComparative;

  public mounted(): void {
  }
}

