import { StrapiFile } from './strapiFile';

export class SectionGalery {
  public id!: string;
  public position!: number;
  public background!: string;
  public sectionType!: string;

  public files: StrapiFile[] = [];
  public fileUrls: string[] = [];
  public fileSmallUrls: string[] = [];

  public updatedAt!: string;
  public createdAt!: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.position = +data.position;
      this.background = data.background;
      this.sectionType = data.section_type;

      if (data.files) {
        data.files.forEach((element: any) => {
          let newFile = new StrapiFile(element);
          this.files.push(newFile);

          this.fileUrls.push(newFile.url);
          this.fileSmallUrls.push(newFile.medium);
        });
      }

      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
    }
  }
}
