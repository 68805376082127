import { StrapiFile } from './strapiFile';

export class SectionPhotoText {
  public id!: number;
  public text!: string;
  public textDark!: boolean;
  public filePosition!: string;
  public fileHeight!: string;
  public fileMaxHeight!: number;
  public textAlign!: string;
  public justifyContent!: string;
  public background!: string;
  public position!: number;
  public sectionType!: string;
  public videoControls!: boolean;
  public videoLoop!: boolean;
  public videoAutoplay!: boolean;
  public videoMuted!: boolean;
  public videoEmbed!: string;

  // button
  public enableButton!: boolean;
  public buttonTargetBlank!: boolean;
  public buttonText!: string;
  public buttonUrl!: string;

  // file
  public file!: StrapiFile;
  public videoThumbnail!: StrapiFile;

  public updatedAt!: string;
  public createdAt!: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.text = data.text;
      this.textDark = data.text_dark? true : false;
      this.filePosition = data.file_position;
      this.fileHeight = data.file_height;
      this.fileMaxHeight = data.file_max_height;
      this.textAlign = data.text_align;
      this.justifyContent = data.justify_content;
      this.background = data.background;
      this.position = +data.position;
      this.sectionType = data.section_type;
      this.videoControls = data.video_controls;
      this.videoLoop = data.video_loop;
      this.videoAutoplay = data.video_autoplay;
      this.videoMuted = data.video_muted;
      this.videoEmbed = data.video_embed;

      this.enableButton = data.enable_button? true : false;
      this.buttonTargetBlank = data.button_target_blank? true : false;
      this.buttonText = data.button_text;
      this.buttonUrl = data.button_url;

      if (data.file) {
        this.file = new StrapiFile(data.file);
      }

      if (data.video_thumbnail) {
        this.videoThumbnail = new StrapiFile(data.video_thumbnail);
      }
      
      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
    }
  }

  get maxHeight(): string | null {
    if (this.fileMaxHeight) {
      return `${this.fileMaxHeight}px`;
    }

    return null;
  }
}
