import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SectionTextText } from '@/class/sectionTextText';
// @ts-ignore
import Media from '@dongido/vue-viaudio';

const auxComponent: any = {};

if (typeof window !== 'undefined') {
  auxComponent.Media = Media;
}

@Component({
  components: auxComponent,
})
export default class SectionTextTextComponent extends Vue {
  @Prop({ default: null })
  // @ts-ignore
  public section: SectionTextText;

  public sectionClass: string = 'full';
  public leftBackground: string = "#fff";
  public rightBackground: string = "#fff";
  public rightJustifyContent: string = 'justify-start';
  public leftJustifyContent: string = 'justify-start';
  public leftTextAlignment: string = 'text-left';
  public rightTextAlignment: string = 'text-left';

  public mounted(): void {
    this.initSettings();
  }

  initSettings() {
    // bg
    if (this.section.leftBackground) {
      this.leftBackground = this.section.leftBackground;
    }

    if (this.section.rightBackground) {
      this.rightBackground = this.section.rightBackground;
    }


    // justify
    if (this.section.leftJustifyContent === 'end') {
      this.leftJustifyContent = 'justify-end';
    } else if (this.section.leftJustifyContent === 'center') {
      this.leftJustifyContent = 'justify-center';
    }

    if (this.section.rightJustifyContent === 'end') {
      this.rightJustifyContent = 'justify-end';
    } else if (this.section.rightJustifyContent === 'center') {
      this.rightJustifyContent = 'justify-center';
    }


    // alignment
    if (this.section.leftTextAlign === 'right') {
      this.leftTextAlignment = 'text-right';
    } else if (this.section.leftTextAlign === 'center') {
      this.leftTextAlignment = 'text-center';
    }

    if (this.section.rightTextAlign === 'right') {
      this.rightTextAlignment = 'text-right';
    } else if (this.section.rightTextAlign === 'center') {
      this.rightTextAlignment = 'text-center';
    }


    // dark
    if (this.section.leftTextDark) {
      this.leftTextAlignment = `${this.leftTextAlignment} text-dark`;
    }

    if (this.section.rightTextDark) {
      this.rightTextAlignment = `${this.rightTextAlignment} text-dark`;
    }
  }

  public redirect(url: string, isTargetBlank: boolean) {
    if (url) {
      let target = isTargetBlank? '_blank' : '_self';
      window.open(url, target);
    }
  }
}

