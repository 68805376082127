import { StrapiFile } from './strapiFile';

export class SectionImageText {
  public id!: string;
  public title!: string;
  public subtitle!: string;
  public textDark!: boolean;
  public textAlign!: string;
  public position!: number;
  public sectionType!: string;
  public overlayColor!: string;
  public overlayTransparency!: number;

  public backgroundFile!: StrapiFile;

  public updatedAt!: string;
  public createdAt!: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.title = data.title;
      this.subtitle = data.subtitle;
      this.textDark = data.text_dark? true : false;
      this.textAlign = data.text_align;
      this.position = +data.position;
      this.sectionType = data.section_type;
      this.overlayColor = data.overlay_color;
      this.overlayTransparency = +data.overlay_transparency;

      if (data.background_file) {
        this.backgroundFile = new StrapiFile(data.background_file);
      }

      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
    }
  }
}
