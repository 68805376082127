import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SectionPhotoText } from '@/class/sectionPhotoText';
// @ts-ignore
import Media from '@dongido/vue-viaudio';

const auxComponent: any = {};

if (typeof window !== 'undefined') {
  auxComponent.Media = Media;
}

@Component({
  components: auxComponent,
})
export default class SectionPhotoTextComponent extends Vue {
  @Prop({ default: null })
  // @ts-ignore
  public section: SectionPhotoText;

  public sectionClass: string = 'normal';
  public sectionBackground: string = "#fff";
  public sectionJustifyContent: string = 'justify-start';
  public textAlignment: string = 'text-left';
  public showImage: boolean = false;
  public showEmbed: boolean = false;

  public mounted(): void {
    this.initSettings();

    this.$nextTick(function () {
      this.showEmbed = true;
    })
  }

  initSettings() {
    if (this.section.background) {
      this.sectionBackground = this.section.background;
    }

    if (this.section.fileHeight === 'full') {
      this.sectionClass = 'full';
    }

    if (this.section.justifyContent === 'end') {
      this.sectionJustifyContent = 'justify-end';
    } else if (this.section.justifyContent === 'center') {
      this.sectionJustifyContent = 'justify-center';
    }

    if (this.section.textAlign === 'right') {
      this.textAlignment = 'text-right';
    } else if (this.section.textAlign === 'center') {
      this.textAlignment = 'text-center';
    }

    if (this.section.textDark) {
      this.textAlignment = `${this.textAlignment} text-dark`;
    }

    this.showImage = this.isImage(this.section.file);
  }


  public isImage(file: any): boolean {
    if (!file || !file.mime) return false;    

    var types = [
      'image/ief',
      'image/jpeg',
      'image/png',
      'image/pipeg',
      'image/svg+xml',
      'image/svg',
      'image/tiff',
      'image/x-cmu-raster',
      'image/x-cmx',
      'image/x-icon',
      'image/x-portable-anymap',
      'image/x-portable-bitmap',
      'image/x-portable-graymap',
      'image/x-portable-pixmap',
      'image/x-rgb',
      'image/x-xbitmap',
      'image/x-xpixmap',
      'image/x-xwindowdump',
      'image/webp'
    ];
    return types.indexOf(file.mime) > -1 ? true : false;
  }

  public redirect(url: string, isTargetBlank: boolean) {
    if (url) {
      let target = isTargetBlank? '_blank' : '_self';
      window.open(url, target);
    }
  }

  public setControls(html: string): string {
    let re = /<iframe.*?src="(.*?)".*?<\/iframe>/
    let src = re.exec(html);

    if (src) {
     return html.replace(src[1], `${src[1]}?hide_owner=true&hide_speed=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`)
    }

    return html;
  }
}

