export class SectionTextOnly {
  public id!: string;
  public text!: string;
  public textDark!: boolean;
  public textSize!: string;
  public background!: string;
  public position!: number;
  public sectionType!: string;

  // button
  public enableButton!: boolean;
  public buttonTargetBlank!: boolean;
  public buttonText!: string;
  public buttonUrl!: string;

  public updatedAt!: string;
  public createdAt!: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.text = data.text;
      this.textDark = data.text_dark? true : false;
      this.textSize = !data.text_size? 'large' : data.text_size;
      this.background = data.background;
      this.position = +data.position;
      this.sectionType = data.section_type;

      this.enableButton = data.enable_button? true : false;
      this.buttonTargetBlank = data.button_target_blank? true : false;
      this.buttonText = data.button_text;
      this.buttonUrl = data.button_url;

      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
    }
  }
}
