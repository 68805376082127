import { StrapiFile } from "./strapiFile";

export class SectionCollage {
  public id!: string;
  public position!: number;
  public background!: string;

  public sectionType!: string;

  public images: StrapiFile[] = [];

  public updatedAt!: string;
  public createdAt!: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.position = +data.position;
      this.background = data.background;
      this.sectionType = data.section_type;

      if (data.images) {
        data.images.forEach((element: any) => {
          this.images.push(new StrapiFile(element));
        });
      }

      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
    }
  }

  get swipePictures(): any[] {
    let list: any[] = [];
    this.images.forEach(element => {
      list.push({
        src: element.url,
        width: element.width,
        height: element.height,
        small: element.small || element.url,
        smallWidth: element.smallWidth || element.width,
        smallHeight: element.smallHeight || element.height,
        alt: element.alt
      })
    });
    
    return list;
  }
}
