import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SectionPhotoPhoto } from '@/class/sectionPhotoPhoto';

const auxComponent: any = {};

@Component({
  components: auxComponent,
})
export default class SectionPhotoPhotoComponent extends Vue {
  @Prop({ default: null })
  // @ts-ignore
  public section: SectionPhotoPhoto;

  public sectionClass: string = 'normal';

  public mounted(): void {
    if (this.section.imagesFit === 'full') {
      this.sectionClass = 'full';
    }
  }
}

