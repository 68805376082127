import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';


@Component({
  components: {},
})
export default class SectionTextOnlyComponent extends Vue {
  @Prop({ default: null })
  // @ts-ignore
  public section: SectionTextOnly;

  public mounted(): void {}


  public redirect(url: string, isTargetBlank: boolean) {
    if (url) {
      let target = isTargetBlank? '_blank' : '_self';
      window.open(url, target);
    }
  }
}

