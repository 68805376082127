import { StrapiFile } from "./strapiFile";

export class SectionPhotoPhoto {
  public id!: string;
  public position!: number;
  public background!: string;
  public imagesFit!: string;

  public sectionType!: string;  

  public img1!: StrapiFile;
  public img2!: StrapiFile;

  public updatedAt!: string;
  public createdAt!: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.position = +data.position;
      this.background = data.background;
      this.imagesFit = data.images_fit;

      this.sectionType = data.section_type;

      if (data.img1) {
        this.img1 = new StrapiFile(data.img1);
      }

      if (data.img2) {
        this.img2 = new StrapiFile(data.img2);
      }

      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
    }
  }
}
