import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { StrapiFile } from '@/class/strapiFile';
import { SectionImageText } from '@/class/sectionImageText';
// @ts-ignore
import Media from '@dongido/vue-viaudio';

const auxComponent: any = {};

if (typeof window !== 'undefined') {
  auxComponent.Media = Media;
}

@Component({
  components: auxComponent,
})
export default class SectionImageTextComponent extends Vue {
  @Prop({ default: null })
  // @ts-ignore
  public section: SectionImageText;

  public mounted(): void {}

  public isImage(file: StrapiFile): boolean {
    if (!file || !file.mime) return false; 

    var types = [
      'image/ief',
      'image/jpeg',
      'image/png',
      'image/pipeg',
      'image/svg+xml',
      'image/svg',
      'image/tiff',
      'image/x-cmu-raster',
      'image/x-cmx',
      'image/x-icon',
      'image/x-portable-anymap',
      'image/x-portable-bitmap',
      'image/x-portable-graymap',
      'image/x-portable-pixmap',
      'image/x-rgb',
      'image/x-xbitmap',
      'image/x-xpixmap',
      'image/x-xwindowdump',
      'image/webp'
    ];
    return types.indexOf(file.mime) > -1 ? true : false;
  }

  public getUrl(file: StrapiFile): string {
    // @ts-ignore
    //let url = (this.$mq === 'sm' || this.$mq === 'xs')? file.medium : file.url;
    return `url(${file.url})`;
  }

  public hex2rgba(hex: string, transparency: number = 100): string {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    const alpha = 1 - (transparency/100);
    
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };
}

